<template>
  <v-scroll-y-transition group>
    <v-hover v-for="(product, index) in products" :key="product.id">
      <template v-slot:default="{ hover }">
        <v-card
          link
          :elevation="hover ? 10 : 0"
          :class="{ 'mb-2': index < products.length }"
        >
          <v-list-item class="ma-0 pa-2">
            <v-avatar class="py-1 ma-1" size="95" :tile="productShape">
              <v-img contain :src="product.photoURL" v-if="product.photoURL">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card
                color="grey lighten-3"
                v-else
                elevation="0"
                height="100%"
                width="100%"
              >
                <v-icon large>image</v-icon>
              </v-card>
            </v-avatar>
            <v-list-item-content class="pa-0 ma-0 ml-2">
              <h3
                class="font-weight-medium text-subtitle-1 ma-0 text-truncate text-capitalize"
              >
                {{ product.name }}
              </h3>
              <p
                class="caption mb-0 text-truncate"
                style="margin-top: -6px !important"
              >
                {{ product.description }}
              </p>
              <p class="text-h6 ma-0 price--text">
                $ {{ product.price }} <span class="caption">MXN</span>
              </p>
              <p class="ma-0 caption text--secondary">
                Código: {{ product.barcode }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
    </v-hover>
  </v-scroll-y-transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    products: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    ...mapGetters("business", ["getBusinessDesign"]),
    productShape() {
      if (this.getBusinessDesign) {
        if (this.getBusinessDesign.product_shape === "circle") return false;
        else return true;
      } else return true;
    }
  }
};
</script>
