<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="scroll-y-reverse-transition"
    >
      <v-app-bar fixed elevation="0" class="white">
        <div>
          <v-btn icon large @click="dialog = false"
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </div>
        <div class="text-h5">Filtros</div>
      </v-app-bar>
      <v-card>
        <v-container class="mt-5 pt-10">
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <p class="ma-0 text-center mt-2 text-h6">Precio</p>
                <div class="px-6">
                  <v-btn block elevation="0" class="my-2">Mayor a $2000</v-btn>
                  <v-btn block elevation="0" class="my-2">$1000 a $2000</v-btn>
                  <v-btn block elevation="0" class="my-2">$100 a $500</v-btn>
                  <v-btn block elevation="0" class="my-2">menor a $100</v-btn>
                </div>
                <div class="d-flex align-center pa-2 mx-4">
                  <v-text-field
                    label="Inicial"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                  <div style="width: 50px" class="px-1">
                    <v-divider></v-divider>
                  </div>
                  <v-text-field
                    label="Final"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card outlined>
                <p class="ma-0 text-center mt-2 text-h6">Ordenar por</p>
                <div class="px-6">
                  <v-btn block elevation="0" class="my-2">Mayor precio</v-btn>
                  <v-btn block elevation="0" class="my-2">Menor precio</v-btn>
                  <v-btn block elevation="0" class="my-2">Más relevantes</v-btn>
                  <v-btn block elevation="0" class="my-2"
                    >Nombre de producto</v-btn
                  >
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" class="mb-12">
              <v-card outlined>
                <p class="ma-0 text-center mt-2 text-h6">Categoria</p>
                <div class="px-6">
                  <v-btn block elevation="0" class="my-2">Cosmeticos</v-btn>
                  <v-btn block elevation="0" class="my-2">Coleccion</v-btn>
                  <v-btn block elevation="0" class="my-2">Abarrotes</v-btn>
                  <v-btn block elevation="0" class="my-2">Camisetas</v-btn>
                </div>
              </v-card>
            </v-col>
            <v-btn
              fixed
              bottom
              color="primary center-btn-filter"
              dark
              @click="dialog = false"
              >Aplicar</v-btn
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EventBus from "@/config/bus";
export default {
  data() {
    return {
      dialog: false
    };
  },
  mounted() {
    EventBus.$on("TOGGLE_DIALOG_FILTER", () => {
      this.dialog = !this.dialog;
    });
  }
};
</script>

<style scoped>
.center-btn-filter {
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
}
</style>
