<template>
  <div>
    <v-container class="background">
      <v-row>
        <v-col cols="6" class="pa-0 pr-1" v-if="!$vuetify.breakpoint.lgAndUp">
          <v-btn block text class="white" @click="openFilters()">Filtros</v-btn>
        </v-col>
        <v-col
          cols="6"
          class="pa-0 pl-1"
          v-if="!$vuetify.breakpoint.lgAndUp"
          :class="{ 'mb-2': viewMode === 'grid' }"
        >
          <v-btn block text class="white" @click="toogleViewMode(null)">
            Vista
            <v-icon class="ml-1" v-if="viewMode === 'grid'">view_module</v-icon>
            <v-icon class="ml-1" small v-else>view_day</v-icon>
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="12" v-if="notFoundBuisness">
          <h3 class="text-h4 mt-2">404</h3>
          <h3 class="text-h5 mb-2">Tienda no encontrado</h3>
          <p class="subheadline ma-0">
            La Tienda que estás buscando no existe o a cambiado de dirección por
            favor corrobora el nombre de la tienda e intenta nuevamente.
          </p>
        </v-col>
        <v-col cols="3" lg="3" xl="3" v-if="$vuetify.breakpoint.lgAndUp">
          <app-drawer-business
            :notFoundBuisness="notFoundBuisness"
          ></app-drawer-business>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="9" xl="9" id="infinite-list">
          <div
            class="text-right mb-1"
            :class="{ 'mb-3': viewMode === 'grid' }"
            v-if="$vuetify.breakpoint.lgAndUp"
          >
            <v-btn
              @click="toogleViewMode('list')"
              icon
              :color="viewMode === 'list' ? 'primary' : ''"
              ><v-icon size="23">view_day</v-icon></v-btn
            >
            <v-btn
              @click="toogleViewMode('grid')"
              icon
              :color="viewMode === 'grid' ? 'primary' : ''"
              ><v-icon size="26">view_module</v-icon></v-btn
            >
          </div>
          <app-list-view
            v-if="viewMode === 'list'"
            :products="products"
          ></app-list-view>
          <app-grid-view
            v-if="viewMode === 'grid'"
            :products="products"
          ></app-grid-view>
          <div class="my-3 text-center" v-if="notMoreProducts">
            <p class="ma-0 caption">No hay mas productos :(</p>
          </div>
          <div class="my-3 text-center" v-if="loadingProducts">
            <p class="mb-0 caption">
              Cargando mas productos
            </p>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div class="my-3 text-center" v-if="loadingFirstData">
            <p class="mb-0 caption">
              Cargando todos los productos,
            </p>
            <p class="mb-2 caption">espera un momento :)</p>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
      <app-filter></app-filter>
    </v-container>
    <SsencialBusinessFooter
      :businessData="businessData"
      :loadingBusinessData="loadingFirstData"
    />
  </div>
</template>

<script>
import { database } from "@/config/firebase";
import { toggleDialogFilter } from "@/services/Dialogs";
import Filter from "../components/marketplace/Filters";
import { errorMessage } from "@/services/MessageService";
import DrawerBusiness from "../components/marketplace/DrawerBusiness";
import ListView from "../components/marketplace/ListView";
import GridView from "../components/marketplace/GridView";
import BusinessFooter from "@/components/marketplace/Footer";
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      products: [],
      firstKnownKeyDatabase: null,
      loadingProducts: false,
      notMoreProducts: false,
      notFoundBuisness: false,
      loadingFirstData: false,
      bottom: false
    };
  },
  created() {
    this.getBuinessDatabase(this.businessName);
    window.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
  },
  computed: {
    ...mapState("business", ["businessData"]),
    ...mapState("app", ["viewMode"])
  },
  destroyed() {
    window.removeEventListener("scroll", () => {
      this.bottom = false;
    });
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.getMoreProducts();
      }
    },
    $route(to, from) {
      if (to.params.businessName != from.params.businessName) {
        this.products = [];
        this.firstKnownKeyDatabase = null;
        this.loadingProducts = false;
        this.notMoreProducts = false;
        this.notFoundBuisness = false;
        this.loadingFirstData = false;
        this.bottom = false;
        this.getBuinessDatabase(to.params.businessName);
      }
    }
  },
  methods: {
    ...mapActions("app", ["initBuinessDatabase", "changeViewModeList"]),
    bottomVisible() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight)
        return true;
      else return false;
    },
    async getBuinessDatabase(businessName) {
      this.loadingFirstData = true;
      const resultBusiness = await this.initBuinessDatabase(businessName);
      switch (resultBusiness) {
        case "NOT_FOUND":
          this.loadingFirstData = false;
          this.notFoundBuisness = true;
          break;
        case "OK":
          this.getProducts();
          break;
        default:
          this.loadingFirstData = false;
          break;
      }
    },
    getProducts() {
      try {
        database("store")
          .orderByKey()
          .limitToLast(20)
          .once("value", snapshot => {
            if (snapshot.exists()) {
              let productKeys = Object.keys(snapshot.val()).reverse();
              let childrenKey = productKeys[productKeys.length - 1];
              for (const productKey of productKeys) {
                const product = {
                  name: snapshot.val()[productKey].name_product,
                  description: snapshot.val()[productKey].description,
                  barcode: snapshot.val()[productKey].barcode,
                  price: snapshot.val()[productKey].price,
                  category: snapshot.val()[productKey].category,
                  photoURL: snapshot.val()[productKey].photoURL
                    ? snapshot.val()[productKey].photoURL
                    : null,
                  id: productKey
                };
                this.products.push(product);
              }
              this.firstKnownKeyDatabase = childrenKey;
              this.loadingFirstData = false;
            } else {
              this.loadingFirstData = false;
              this.notMoreProducts = true;
            }
          });
      } catch {
        errorMessage({
          message: "No fue posible obtener los productos"
        });
        this.loadingFirstData = false;
      }
    },
    async getMoreProducts() {
      this.loadingProducts = true;
      try {
        if (this.firstKnownKeyDatabase) {
          database("store")
            .orderByKey()
            .endAt(this.firstKnownKeyDatabase)
            .limitToLast(21)
            .once("value", snapshot => {
              let productKeys = Object.keys(snapshot.val())
                .reverse()
                .slice(1);
              if (productKeys.length < 3) this.notMoreProducts = true;
              let childrenKey = productKeys[productKeys.length - 1];
              for (const productKey of productKeys) {
                const product = {
                  name: snapshot.val()[productKey].name_product,
                  description: snapshot.val()[productKey].description,
                  barcode: snapshot.val()[productKey].barcode,
                  price: snapshot.val()[productKey].price,
                  category: snapshot.val()[productKey].category,
                  photoURL: snapshot.val()[productKey].photoURL
                    ? snapshot.val()[productKey].photoURL
                    : null,
                  id: productKey
                };
                this.products.push(product);
              }
              this.firstKnownKeyDatabase = childrenKey;
              this.loadingProducts = false;
            });
        } else {
          this.loadingProducts = false;
          this.notMoreProducts = true;
        }
      } catch {
        errorMessage({
          message: "No fue posible obtener los productos"
        });
      }
    },
    excludeKeyValueDatabase(key) {
      const keyTransform = parseInt(key) - 1;
      return keyTransform.toString();
    },
    toogleViewMode(mode) {
      this.changeViewModeList(mode);
    },
    openFilters() {
      toggleDialogFilter();
    }
  },
  components: {
    AppFilter: Filter,
    AppDrawerBusiness: DrawerBusiness,
    AppListView: ListView,
    AppGridView: GridView,
    SsencialBusinessFooter: BusinessFooter
  },
  props: {
    businessName: {
      required: true,
      type: String
    }
  }
};
</script>
