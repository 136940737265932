<template>
  <v-scroll-y-transition>
    <v-row>
      <v-col
        sm="12"
        md="6"
        lg="4"
        xl="3"
        cols="12"
        class="py-1"
        v-for="product in products"
        :key="product.id + '-grid'"
      >
        <v-scroll-y-transition>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 10 : 0" link>
                <v-container>
                  <v-img
                    height="250px"
                    contain
                    :src="product.photoURL"
                    v-if="product.photoURL"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-card
                    color="grey lighten-3 d-flex justify-center"
                    v-else
                    elevation="0"
                    height="250px"
                    width="100%"
                  >
                    <v-icon size="100">image</v-icon>
                  </v-card>
                  <div class="ma-2">
                    <h3 class="text-h6 ma-0 text-truncate">
                      {{ product.name }}
                    </h3>
                    <p
                      class="caption mb-0 text-truncate"
                      style="margin-top: -6px !important"
                    >
                      {{ product.description }}
                    </p>
                    <p class="text-h6 ma-0 price--text">
                      $ {{ product.price }}
                      <span class="caption">MXN</span>
                    </p>
                    <p class="ma-0 caption text--secondary">
                      Código: {{ product.barcode }}
                    </p>
                  </div>
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-scroll-y-transition>
</template>

<script>
export default {
  props: {
    products: {
      default: () => [],
      type: Array
    }
  }
};
</script>
